import { useEffect, useState } from "react";
import "./Reviews.css";
import { Container } from "react-bootstrap";
import { apiClient } from "../../../Utilities/apiClient";
import ReviewCard from "../../Reusables/ReviewCard/ReviewCard";

const Reviews: React.FC = () => {

    const [reviews, setReviews] = useState<any>([]);
    const handleAccept = async (id: number) => {
        const response = await apiClient.put('/trails/review/accept', { reviewId: id });
        if(response.status === 200) {
            const newReviews = reviews.filter((review: any) => review.id !== id);
            setReviews(newReviews);
        }
    }
    const handleReject = async (id: number) => {
        const response = await apiClient.put('/trails/review/reject', { reviewId: id });
        if(response.status === 200) {
            const newReviews = reviews.filter((review: any) => review.id !== id);
            setReviews(newReviews);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const response = await apiClient.get('/trails/reviews/pending');
                setReviews(response.data.reviews)
                // const data = await response.json();
                // setReviews(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    
    const renderReviews = () => {
        return (
            <div className="review-cards">
                {reviews.map((review: any) => (
                    <ReviewCard
                        key={review.id}
                        rating={review.rating}
                        text={review.text}
                        onAccept={() => handleAccept(review.id)}
                        onReject={() => handleReject(review.id)}
                        photos={review.photos}                    
                    />
                ))}
            </div>
        );
    }
    
    return (
        <Container className="main-container">
            <div>
                <h3>Reviews</h3>
                {renderReviews()}
            </div>
        </Container>
    );
}

export default Reviews;
