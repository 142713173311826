import React from "react";
import logo from "../../../Assets/icon.png";
import "./Main.css";

const Main: React.FC = () => {

  return (
    <div
      className="Body"
      style={{
        backgroundSize: "50px",
      }}
    >
      <div className="intro">
        <img src={logo} width={350} alt="logo"></img>
      </div>
    </div>
  );
};

export default Main;
