export function parseKMLAndGetCoordinates(kmlContent: string): [number, number][] {
    const parser = new DOMParser();
    // Parse the KML content into an XML document
    const xmlDoc = parser.parseFromString(kmlContent, "application/xml");
    const coordinates = xmlDoc.getElementsByTagName("coordinates");

    let coordPairs: [number, number][] = [];

    for (let i = 0; i < coordinates.length; i++) {
        // Split the coordinates content by whitespace and/or commas to separate out each pair
        const coords = coordinates[i].textContent?.trim().split(/\s+/) || [];

        coordPairs = coordPairs.concat(coords.map(coord => {
            const parts = coord.split(",");
            const longitude = parseFloat(parts[0]);
            const latitude = parseFloat(parts[1]);
            return [longitude, latitude];
        }));
    }

    return coordPairs;
}

export function generateKML(coordinates: number[][]): string {
    const kmlHeader = `<?xml version="1.0" encoding="UTF-8"?>
        <kml xmlns="http://www.opengis.net/kml/2.2">
        <Document>`;

    const kmlFooter = `</Document>
        </kml>`;

    const kmlBody = coordinates.map(coord => {
        return `
            <Placemark>
                <Point>
                    <coordinates>${coord[1]},${coord[0]}</coordinates>
                </Point>
            </Placemark>`;
    }).join('');

    return kmlHeader + kmlBody + kmlFooter;
}


