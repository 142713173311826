import React from 'react';
import { Button } from 'react-bootstrap';
import "./WaypointCard.css"
import { apiClient } from '../../../Utilities/apiClient';

interface WaypointCardProps {
    photo: string;
    description: string;
    name: string;
    longitude: number;
    latitude: number;
    id: number;
}

const WaypointCard: React.FC<WaypointCardProps> = ({
    photo,
    description,
    longitude,
    latitude,
    name,
    id,
}) => {

    const deleteWaypoint = () => {
        apiClient.post(`/trails/waypoint/deactivate/${id}`)
        .then( res => {
            if(res.status === 200) {
                alert("Waypoint Deleted")
            }
          }
        )
    }
    return (
        <div className="waypoint-card-container">
            <img src={photo} alt="Waypoint" width={200}/>
            <div className="waypoint-data">
              <p> Description : {description}</p>
              <p> Longitude : {longitude}</p>
              <p> Latitude : {latitude}</p>
              <p> Name : {name}</p>
            </div>
            <div className="actions">
              <Button variant="dark" onClick={deleteWaypoint} className='trail-action-btn'>Delete Waypoint</Button>
            </div>
        </div>
    );
};

export default WaypointCard;