import React, { useEffect, useState } from 'react';
import { InputGroup, Form } from 'react-bootstrap';

interface StartingPointInputProps {
    coordinates: [number, number]; 
    onChange: (key: string, coordinates: [number, number]) => void;
    field: string;
    longitudeLabel?: string;
    latitudeLabel?: string;
}

const StartingPointInput: React.FC<StartingPointInputProps> = ({ coordinates, onChange, field, latitudeLabel, longitudeLabel } ) => {
    const [longitude, setLongitude] = useState(coordinates[0]);
    const [latitude, setLatitude] = useState(coordinates[1]);

    useEffect(() => {
        setLongitude(coordinates[0]);
        setLatitude(coordinates[1]);
    }, [coordinates]);

    const setCoordinateLatitude = (latitude: number) => {
        setLatitude(latitude);
        onChange(field, [longitude, latitude]);
    }

    const setCoordinateLongitude = (longitude: number) => {
        setLongitude(longitude);
        onChange(field, [longitude, latitude]);
    }

    return (
        <>
            <InputGroup size="sm" className="mb-3">
                <InputGroup.Text                 
                    id="inputGroup-sizing-sm" 
                    className="meta-element-label"
                >{longitudeLabel ? longitudeLabel : 'Starting Point Long'} </InputGroup.Text>
                <Form.Control
                    type="number"
                    value={longitude}
                    onChange={(e) => setCoordinateLongitude(Number(e.target.value))}
                    aria-label="Longitude"
                    step="0.0001"
                    min="-180"
                    max="180"
                />
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
                <InputGroup.Text                 
                    id="inputGroup-sizing-sm" 
                    className="meta-element-label"
                >{latitudeLabel ? latitudeLabel : 'Starting Point Lat'}</InputGroup.Text>
                <Form.Control
                    type="number"
                    value={latitude}
                    onChange={(e) => setCoordinateLatitude(Number(e.target.value))}
                    aria-label="Latitude"
                    step="0.0001"
                    min="-90"
                    max="90"
                />
            </InputGroup>
        </>
    );
};

export default StartingPointInput;
