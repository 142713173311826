import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Modal, Nav } from 'react-bootstrap';
import './WaypointsSidebar.css'; 
import { Link } from 'react-router-dom';
import StartingPointInput from '../../../../Pages/Trail/components/startingPointInput';
import { apiClient } from '../../../../../Utilities/apiClient';

type WaypointsSidebarProps = {
    currentTrailId: string;
    currentPage: string;
}

interface Coordinates {
    longitude: number;
    latitude: number;
}

const WaypointsSidebar: React.FC<WaypointsSidebarProps> = ( {currentTrailId, currentPage}) => {

    const [trailId] = useState<string>(currentTrailId);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [coordinates, setCoordinates] = useState<Coordinates>({ longitude: 0, latitude: 0 });
    const [photo, setPhoto] = useState<File | null>(null);
    const [photoPreview, setPhotoPreview] = useState<string>('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            setPhoto(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCoordinateChange = (field: string, coordinates: [number, number]) => {
        setCoordinates({ longitude: coordinates[0], latitude: coordinates[1] });
    };

    const handleSave = () => {
         if (!name || !description || !photo || !coordinates) {
            alert("Please fill in all fields")
            return;
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('longitude', coordinates.longitude.toString());
        formData.append('latitude', coordinates.latitude.toString());
        formData.append('photo', photo);
        formData.append('trailId', trailId);

        apiClient.post(`/trails/waypoint`, formData, {
            contentType: 'multipart/form-data'
        })
        .then(() => {
            alert('Waypoint added successfully');
            handleClose();
        })
    }

    return (
        <div className="sidebar">
            <Nav className="me-auto"> 
                <Link 
                to={"/trail/" + currentTrailId}
                className="nav-link" 
                >
                Back to trail
                </Link>
                <Button variant="secondary" className="add-waypoint-button" onClick={handleShow}>Add Waypoint</Button>
            </Nav>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add waypoint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form encType="multipart/form-data">
                        <Form.Group controlId="formName" className='mb-3'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter name"
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescription" className='mb-3'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter a description"
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhoto" className='mb-3'>
                            <Form.Label>Photo</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handlePhotoChange}
                                className='mb-3'
                            />
                            {photoPreview && (
                                <img src={photoPreview} alt="Preview" width={300} height={300}/>
                            )}
                        </Form.Group>
                        <StartingPointInput
                            coordinates={[coordinates.longitude, coordinates.latitude]}
                            field="startingPoint"
                            longitudeLabel="Long"
                            latitudeLabel="Lat"
                            onChange={handleCoordinateChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default WaypointsSidebar;
