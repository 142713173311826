import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import websiteConfigReducer from './slices/websiteConfigSlice';
import authSlice from './slices/authSlice';
import TrailFiltersSlice from './slices/TrailFiltersSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    websiteConfig: websiteConfigReducer,
    auth: authSlice,
    trailFilters: TrailFiltersSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
