import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TrailFiltersState {
  sortBy: string;
  name: string;
  statusFilter: string;
}

const initialState: TrailFiltersState = {
  sortBy: '',
  name: '',
  statusFilter: '',
};

const trailFiltersSlice = createSlice({
  name: 'trailFilters',
  initialState,
  reducers: {
    setTrailFilters(state, action: PayloadAction<any>) {  
      state.name = action.payload.name;
      state.sortBy = action.payload.sortBy;
      state.statusFilter = action.payload.statusFilter;
    },
  },
});

export const { setTrailFilters } = trailFiltersSlice.actions;
export default trailFiltersSlice.reducer;
