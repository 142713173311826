import React from "react";
import { useState, useEffect } from "react";
import { InputGroup, Form } from "react-bootstrap";
import { TimeInterval } from "../../../Data/Trails";

interface IntervalInputProps {
    value: TimeInterval | null | undefined;
    onChange: (key: string, value: string) => void;
    field: string;
}

const formatInterval = ({ hours, minutes }: { hours: number, minutes: number }): string => {
    return `${hours || 0} hours ${minutes || 0} minutes`;
};

const IntervalInput: React.FC<IntervalInputProps> = ({ value, onChange, field }) => {
    const returnValidInterval = (interval : TimeInterval | null | undefined) => {
        if(!interval) {
            return { hours: 0, minutes: 0 };
        }

        return interval;
    }

    const { hours, minutes } = returnValidInterval(value);
    const [hoursValue, setHoursValue] = useState(hours);
    const [minutesValue, setMinutesValue] = useState(minutes);


    useEffect(() => {
        const { hours, minutes } = returnValidInterval(value);
        setHoursValue(hours);
        setMinutesValue(minutes);
    } , [value]);

    const setHours = (hours: number) => {
        setHoursValue(hours);
        onChange(field, formatInterval({ hours, minutes: minutesValue }));
    }

    const setMinutes = (minutes: number) => {
        setMinutesValue(minutes);
        onChange(field, formatInterval({ hours: hoursValue, minutes }));
    }

    return (
        <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
                id="inputGroup-sizing-sm" 
                className="meta-element-label"
            >Total Hours</InputGroup.Text>
            <Form.Control
                type="number"
                value={hoursValue}
                onChange={(e) => setHours(Number(e.target.value))}
                aria-label="Hours"
                min="0"
                step="1"
            />
            <InputGroup.Text                 
                id="inputGroup-sizing-sm" 
                className="meta-element-label"
            >Total Minutes</InputGroup.Text>
            <Form.Control
                type="number"
                value={minutesValue}
                onChange={(e) => setMinutes(Number(e.target.value))}
                aria-label="Minutes"
                min="0"
                max="59"
                step="1"
            />
        </InputGroup>
    );
};

export default React.memo(IntervalInput);