import { useEffect, useState } from "react";
import "./Waypoints.css";
import { Container } from "react-bootstrap";
import { apiClient } from "../../../Utilities/apiClient";
import * as _ from 'lodash';
import { Waypoint } from "../../Data/Trails";
import WaypointCard from "../../Reusables/WaypointCard/WaypointCard";

const Waypoints: React.FC = () => {

    const [waypoints, setWaypoints] = useState<Waypoint[]>();


    // add a use effect that fetches the id parameter form the url and makes an api call to get the trail data
    useEffect(() => {
        const id = window.location.pathname.split('/')[2];
        apiClient.get('/trails/waypoints/'+id)
            .then((response) => {
                setWaypoints(response.data.waypoints as Waypoint[]);
            });
    }, []);


    const renderWaypointCards = () => {
        return waypoints?.map((waypoint, index) => {
            return (
                <WaypointCard 
                    key={index}
                    photo={waypoint.photos[0]}
                    name={waypoint.name}
                    description={waypoint.description}
                    longitude={waypoint.location[0]}
                    latitude={waypoint.location[1]}
                    id={waypoint.id}
                />
            );
        });
    }
    
    return (
            <div>
                <h4>Waypoints</h4>
                <Container className="waypoint-cards">
                    {renderWaypointCards()}
                </Container>
            </div>
    );
}

export default Waypoints;
