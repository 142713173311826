import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WebsiteConfigState {
  direction: 'rtl' | 'ltr';
  language: 'en' | 'ar';
}

const initialState: WebsiteConfigState = {
  direction: sessionStorage.getItem('websiteDirectionState') as 'rtl' | 'ltr' || 'rtl',
  language: sessionStorage.getItem('websiteLanguageState') as 'en' | 'ar' || 'ar',
};

const websiteConfigSlice = createSlice({
  name: 'websiteConfig',
  initialState,
  reducers: {
    setDirection(state, action: PayloadAction<'rtl' | 'ltr'>) {
      state.direction = action.payload;
      sessionStorage.setItem('websiteDirectionState', action.payload);
    },
    setLanguage(state, action: PayloadAction<'en' | 'ar'>) {
      state.language = action.payload;
      sessionStorage.setItem('websiteLanguageState', action.payload);
    },
  },
});

export const { setDirection, setLanguage } = websiteConfigSlice.actions;
export default websiteConfigSlice.reducer;
