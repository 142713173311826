import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import './Sidebar.css'; 
import { Link, useLocation } from 'react-router-dom';
import WaypointsSidebar from './SidebarComponents/WaypointsSidebar/WaypointsSidebar';
import TrailsSidebar from './SidebarComponents/TrailSidebar/TrailsSidebar';

const Sidebar: React.FC = () => {

    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<string>('');
    const [currentTrailId, setCurrentTrailId] = useState<string>('');

    useEffect(() => {
        if(location.pathname.includes('waypoints')) {
            setCurrentPage('waypoints');
        } else if (location.pathname.includes('trail/')) {           
            setCurrentTrailId(location.pathname.split('/').pop() || '');
            setCurrentPage('trail');
        }
        else if (location.pathname.includes('trails')) {
            setCurrentPage('trails');
        }
        else {
            setCurrentTrailId('');
            setCurrentPage('');
        }
    }, [location.pathname]);


    const renderCurrentPageSidebar = () => {
        switch(currentPage) {
            case 'trail':
                return (
                    <Nav className="me-auto"> 
                        <Link 
                        to={"/waypoints/" + currentTrailId}
                        className="nav-link" 
                        style={{ color: location.pathname === 'waypoints' ? '#3E5E53' : '' }}
                        >
                        Waypoints 
                        </Link>
                    </Nav>
                );
            case 'trails':
                return (
                    <TrailsSidebar currentPage={currentPage} currentTrailId={currentTrailId}/>
                );
            case 'waypoints':
                return (
                    <WaypointsSidebar currentPage={currentPage} currentTrailId={currentTrailId}/>
                );
            default:
                return null;
        }
    }
    return (
        <div className="sidebar">
            {renderCurrentPageSidebar()}
        </div>
    );
};

export default Sidebar;
